import { useCallback, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, message, Radio, Row, Space, Table } from 'antd';
import moment from 'moment';
import { useImmer } from 'use-immer';
import { TimesheetSummary, useProjectsExtendedTimesheetSummary } from '../../dal';
import { displayDurationFromMinutes, formatCurrency, nullableDataSorter } from '../../common/utils';
import MoneyInput from '../shared/MoneyInput';
import HoursInput from '../Timesheet/HoursInput';
import { calculateGroupSum, formInvoiceFromTimesheetData } from '../../utils';
import { BillableInfo, GroupByType } from '../../entities';
import { Invoice, RangeValueType } from '../../entities/Invoice';

type Props = {
  projectId: string;
  projectBillableRate: number;
  onInvoiceCreated: (data: any) => void;
  editingInvoice?: Invoice | null;
};

const InvoiceCreator = ({ projectId, projectBillableRate, onInvoiceCreated, editingInvoice }: Props) => {
  const [dateRange, setDateRange] = useState<RangeValueType>(editingInvoice?.dateRange);
  const { timesheets } = useProjectsExtendedTimesheetSummary(projectId, dateRange);
  const [customValues, setCustomValues] = useImmer<Record<string, BillableInfo>>({});
  const [globalBillableRate, setGlobalBillableRate] = useState<number>(projectBillableRate);
  const [groupingOption, setGroupingOption] = useState<GroupByType>('');
  const [storedTimesheets, setStoredTimesheets] = useState<TimesheetSummary[] | null>(timesheets);

  useEffect(() => {
    if (JSON.stringify(timesheets) !== JSON.stringify(storedTimesheets) || Object.values(customValues).length === 0) {
      setStoredTimesheets(timesheets);

      const updatedCustomValues = editingInvoice
        ? editingInvoice.details.reduce((acc, detail) => {
            acc[detail.key] = {
              billableHours: detail.billableHours,
              billableRate: detail.billableRate,
              billableTotal: !detail.billableHours && detail.fixedBillableTotal ? detail.fixedBillableTotal : detail.billableTotal,
            };
            return acc;
          }, {})
        : timesheets.reduce((acc, t) => {
            const billableHours = t.totalMinutes / 60;
            const billableRate = Math.round(globalBillableRate * 100) / 100;
            const billableTotal = billableHours * billableRate;

            if (!acc[t.key]) {
              acc[t.key] = {
                billableHours: billableHours,
                billableRate: billableRate,
                billableTotal: t.fixedCost ? t.fixedCost : billableTotal,
              };
            }
            return acc;
          }, {});

      setCustomValues(updatedCustomValues);
    }
  }, [customValues, dateRange, editingInvoice, globalBillableRate, setCustomValues, storedTimesheets, timesheets]);

  const updateCustomValue = useCallback(
    (key: string, field: string, value: any) => {
      setCustomValues(draft => {
        draft[key] ??= { billableHours: 0, billableRate: globalBillableRate, billableTotal: 0 };
        draft[key][field] = value;
      });
    },
    [globalBillableRate, setCustomValues],
  );

  const handleGlobalBillableRateChange = useCallback(
    value => {
      setGlobalBillableRate(value);

      setCustomValues(draft => {
        Object.keys(draft).forEach(key => {
          draft[key]['billableRate'] = value;
        });
      });
    },
    [setCustomValues],
  );

  const submitInvoice = useCallback(() => {
    if (!dateRange) {
      message.error('No date range added');
      return;
    }

    const totalFixedCost = timesheets.map(timesheet => timesheet.fixedCost).reduce((acc, curr) => acc + curr, 0);
    const totalCost = timesheets.map(timesheet => timesheet.totalCost).reduce((acc, curr) => acc + curr, 0);

    const actualValue = totalFixedCost + totalCost;

    const summary = timesheets.reduce(
      (acc, { key, totalMinutes, totalCost, fixedCost }) => {
        acc.summaryBillableHourlySpent += customValues[key].billableHours ? customValues[key].billableTotal : 0;
        acc.summaryTotalMinutes += +totalMinutes || 0;
        acc.summaryBillableHours += customValues[key].billableHours || 0;
        acc.summaryHourlySpent += totalCost || 0;
        acc.summaryFixedSpent += fixedCost || 0;
        acc.summaryBillableFixedSpent += !customValues[key].billableHours ? customValues[key].billableTotal : 0;
        return acc;
      },
      {
        summaryBillableHourlySpent: 0,
        summaryTotalMinutes: 0,
        summaryBillableHours: 0,
        summaryHourlySpent: 0,
        summaryFixedSpent: 0,
        summaryBillableFixedSpent: 0,
      },
    );

    const {
      summaryBillableHourlySpent,
      summaryTotalMinutes,
      summaryBillableHours,
      summaryHourlySpent,
      summaryFixedSpent,
      summaryBillableFixedSpent,
    } = summary;

    const details = timesheets.map(t => ({
      ...t,
      role: t.category,
      phaseName: t.phaseName || '',
      billableHours: customValues[t.key].billableHours,
      billableRate: customValues[t.key].billableRate,
      billableTotal: customValues[t.key].billableHours ? customValues[t.key].billableTotal || 0 : 0,
      fixedBillableTotal: !customValues[t.key].billableHours ? customValues[t.key].billableTotal || 0 : 0,
    }));

    const invoiceCreated = {
      value: Math.round((summaryBillableHourlySpent + summaryBillableFixedSpent) * 100) / 100,
      dateRange,
      markupPercentage: ((1 - actualValue / summaryBillableHourlySpent) * 100).toFixed(2),
      summaryBillableHours,
      summaryBillableHourlySpent,
      summaryHourlySpent,
      summaryFixedSpent,
      summaryTotalMinutes,
      summaryBillableFixedSpent,
      details,
    };

    onInvoiceCreated(invoiceCreated);
  }, [customValues, dateRange, onInvoiceCreated, timesheets]);

  const summary = useCallback(
    pageData => {
      let summaryBillableHourlySpent = 0;
      let summaryTotalMinutes = 0;
      let summaryBillableHours = 0;
      let summaryHourlySpent = 0;
      let summaryFixedSpent = 0;
      let summaryBillableFixedSpent = 0;

      pageData
        .filter(pd => !pd.isGroup)
        .forEach(({ key, totalMinutes, totalCost, fixedCost }) => {
          summaryBillableHourlySpent += customValues[key]?.billableHours ? customValues[key]?.billableTotal || 0 : 0;
          summaryTotalMinutes += +totalMinutes;
          summaryBillableHours += customValues[key]?.billableHours;
          summaryHourlySpent += totalCost;
          summaryFixedSpent += fixedCost;
          summaryBillableFixedSpent += !customValues[key]?.billableHours ? customValues[key]?.billableTotal || 0 : 0;
        });

      return (
        <>
          <Table.Summary.Row className="role-group-row">
            <Table.Summary.Cell index={0} colSpan={groupingOption ? 3 : 4}></Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {displayDurationFromMinutes(summaryTotalMinutes)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="right">
              {formatCurrency(summaryHourlySpent + summaryFixedSpent)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <Input value={displayDurationFromMinutes(Math.round(summaryBillableHours * 60))} bordered={false} readOnly={true} />
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5} align="right">
              {formatCurrency(summaryBillableHourlySpent + summaryBillableFixedSpent)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
          <Table.Summary.Row className="role-group-row">
            <Table.Summary.Cell index={0} colSpan={groupingOption ? 7 : 8} align="right">
              Markup
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {summaryBillableHourlySpent > 0
                ? ((1 - (summaryHourlySpent + summaryFixedSpent) / (summaryBillableHourlySpent + summaryBillableFixedSpent)) * 100).toFixed(2) + '%'
                : ''}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      );
    },
    [customValues, groupingOption],
  );

  const dataSource = formInvoiceFromTimesheetData(timesheets, groupingOption);

  const rowClassName = record => {
    return record.isGroup ? 'role-group-row' : '';
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <DatePicker.RangePicker
          value={dateRange}
          onChange={values => setDateRange(values || [null, null])}
          renderExtraFooter={() => (
            <Row justify="space-between">
              <Col>
                <Button type="link" onClick={() => setDateRange([moment().startOf('month'), moment().endOf('month')])}>
                  This month
                </Button>
                <Button
                  type="link"
                  onClick={() => setDateRange([moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')])}
                >
                  Previous month
                </Button>
                <Button type="link" onClick={() => setDateRange([moment().subtract(30, 'days'), moment()])}>
                  Last 30 days
                </Button>
                <Button type="link" onClick={() => setDateRange([moment().startOf('year'), moment().endOf('year')])}>
                  This year
                </Button>
              </Col>
              <Col>
                <Button type="link" onClick={() => setDateRange([null, null])}>
                  Clear
                </Button>
              </Col>
            </Row>
          )}
        />

        <Radio.Group
          options={[
            { label: 'Ungrouped', value: '' },
            { label: 'Group by role', value: 'role' },
            { label: 'Group by phase', value: 'phase' },
          ]}
          onChange={e => setGroupingOption(e.target.value)}
          value={groupingOption}
          optionType="button"
        />
      </Row>

      <Table dataSource={dataSource} pagination={false} size="small" summary={summary} rowClassName={rowClassName}>
        <Table.Column
          title="Resource"
          dataIndex="userName"
          render={(value, { isGroup, key }: TimesheetSummary) => (isGroup ? key : value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.userName, b.userName))}
        />
        <Table.Column
          title={groupingOption === 'role' ? 'Phase' : 'Role'}
          dataIndex={groupingOption === 'role' ? 'phaseName' : 'category'}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.category, b.category))}
        />
        {!groupingOption && (
          <Table.Column
            title="Phase"
            dataIndex="phaseName"
            sorter={
              !groupingOption &&
              ((a: TimesheetSummary, b: TimesheetSummary) => {
                return nullableDataSorter(a.phaseName, b.phaseName);
              })
            }
          />
        )}
        <Table.Column
          title="Hourly rate"
          width="100px"
          dataIndex="ratePerHour"
          render={(value, { isGroup, fixedCost }: TimesheetSummary) => !isGroup && !fixedCost && formatCurrency(value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.ratePerHour, b.ratePerHour))}
          align="right"
        />
        <Table.Column
          title="Real hours"
          width="100px"
          dataIndex="totalMinutes"
          render={(value, { isGroup }: TimesheetSummary) => !isGroup && displayDurationFromMinutes(value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalMinutes, b.totalMinutes))}
          align="right"
        />
        <Table.Column
          title="Real spend"
          width="100px"
          dataIndex="totalCost"
          render={(value, { isGroup, fixedCost }: TimesheetSummary) => !isGroup && !fixedCost && formatCurrency(value)}
          sorter={
            !groupingOption &&
            ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalCost || a.fixedCost, b.totalCost || b.fixedCost))
          }
          align="right"
        />
        <Table.Column
          title="Billable hours"
          width="100px"
          dataIndex="key"
          align="right"
          render={(value, { isGroup, fixedCostTotal, totalCost }: TimesheetSummary) =>
            isGroup ? (
              <HoursInput
                readOnly
                value={!fixedCostTotal ? calculateGroupSum(customValues, value, 'hours') : ''}
                onChange={(_, number) => updateCustomValue(value, 'billableHours', number)}
              />
            ) : (
              <HoursInput
                readOnly={!totalCost}
                value={totalCost ? customValues[value]?.billableHours : ''}
                onChange={(_, number) => updateCustomValue(value, 'billableHours', number)}
              />
            )
          }
        />
        <Table.Column
          title={
            <div>
              <div>Billable rate</div>

              <MoneyInput value={globalBillableRate} onChange={handleGlobalBillableRateChange} />
            </div>
          }
          width="100px"
          dataIndex="key"
          align="right"
          render={(value, { isGroup, totalCost }: TimesheetSummary) =>
            totalCost ? (
              <MoneyInput value={customValues[value]?.billableRate} onChange={customValue => updateCustomValue(value, 'billableRate', customValue)} />
            ) : (
              !isGroup && <i>Fixed cost</i>
            )
          }
        />
        <Table.Column
          title="Billable spend"
          width="100px"
          dataIndex="key"
          render={(value, { isGroup, fixedCost, totalCost, fixedCostTotal }: TimesheetSummary) =>
            fixedCost ? (
              <MoneyInput
                value={customValues[value]?.billableTotal}
                onChange={customValue => updateCustomValue(value, 'billableTotal', customValue)}
              />
            ) : isGroup && fixedCostTotal ? (
              formatCurrency(calculateGroupSum(customValues, value, 'fixed'))
            ) : isGroup && !fixedCostTotal ? (
              formatCurrency(calculateGroupSum(customValues, value, 'spend'))
            ) : (
              formatCurrency(totalCost ? customValues[value]?.billableHours * customValues[value]?.billableRate : fixedCost)
            )
          }
          align="right"
        />
      </Table>
      <Button type="primary" style={{ width: '100%' }} onClick={submitInvoice}>
        {`${editingInvoice ? 'Save edited' : 'Create'} invoice`}
      </Button>
    </Space>
  );
};

export default InvoiceCreator;
