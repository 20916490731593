import { useCallback, useState } from 'react';
import { Button, Form, Input, Row, Col, DatePicker, Modal, Space, Alert, Popconfirm, Switch, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { EditOutlined } from '@ant-design/icons';
import { Invoice, InvoiceLineItem } from '../../entities/Invoice';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { datePickerFormat } from '../../constants';
import MoneyInput from '../shared/MoneyInput';
import InvoiceTable from './InvoiceTable';
import InvoiceCreator from './InvoiceCreator';
import { PlusOutlined } from '@ant-design/icons';
import { InvoiceStatusTag } from './InvoiceStatusTag';
import { subject } from '@casl/ability';

type InvoiceTableInternalProps = {
  value?: InvoiceLineItem[];
  isClientView?: boolean;
  groupByPhase?: boolean;
  onChange?: (count: number) => void;
};

const InvoiceTableInternal: React.FC<InvoiceTableInternalProps> = ({ value, isClientView, groupByPhase }) => {
  return value && value.length > 0 ? <InvoiceTable details={value} isClientView={isClientView} groupByPhase={groupByPhase} /> : null;
};

type Props = {
  projectId: string;
  invoice: Invoice;
  billableRate: number;
  onCancel: () => void;
  onDelete: (invoiceId: string) => void;
  onSubmit: (invoice: Invoice) => void;
};

const InvoiceModal = ({ projectId, invoice, billableRate, onCancel, onDelete, onSubmit }: Props) => {
  const { ability } = usePermissions();
  const [invoiceForm] = useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onInvoiceFormFinish = useCallback(
    async (invoice: Invoice) => {
      onSubmit(invoice);
    },
    [onSubmit],
  );

  const onInvoiceEditCompleted = useCallback(
    (data: Invoice) => {
      invoiceForm.setFieldsValue(data);
      setIsModalOpen(false);
    },
    [invoiceForm],
  );

  return (
    <Modal
      open={invoice !== null}
      title={
        <Space size="large">
          <Typography.Text>Invoice {invoice?.autoCode}</Typography.Text>
          <InvoiceStatusTag invoice={invoice} />
        </Space>
      }
      onCancel={() => onCancel()}
      width="min(calc(100% - 40px), 1400px)"
      style={{ top: 20 }}
      destroyOnClose
      footer={[
        <Row justify="space-between">
          <Col>
            {invoice.id && (
              <Popconfirm
                title="Do you want to permanently delete this invoice?"
                onConfirm={() => onDelete(invoice.id!)}
                disabled={!ability.can('delete', subject('Invoice', subject('Invoice', invoice)))}
              >
                <Button type="link" danger disabled={!ability.can('delete', subject('Invoice', invoice))}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Col>
          <Col>
            <Button type="text" onClick={() => onCancel()}>
              Cancel
            </Button>
            {invoice?.isActive ? null : (
              <Button
                key="saveAndMakeActive"
                type="primary"
                onClick={() => {
                  invoiceForm.setFieldValue('isActive', true);
                  invoiceForm.submit();
                }}
                disabled={!ability.can('update', subject('Invoice', invoice), 'isActive')}
              >
                Save and make active
              </Button>
            )}
            <Button
              key="save"
              type="primary"
              disabled={!ability.can('create', subject('Invoice', invoice))}
              onClick={() => {
                invoiceForm.submit();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {!invoice?.isActive && <Alert message="This invoice is a draft (not visible to clients)" type="warning" />}
        {invoice && (
          <Form
            form={invoiceForm}
            initialValues={{
              ...invoice,
              estimatedPayDate: invoice.estimatedPayDate ? moment(invoice.estimatedPayDate) : null,
              sentDate: invoice.sentDate ? moment(invoice.sentDate) : null,
              paidDate: invoice.paidDate ? moment(invoice.paidDate) : null,
              dateRange: invoice.dateRange ? invoice.dateRange.map(d => (d ? moment(d) : null)) : null,
            }}
            layout="vertical"
            onFinish={onInvoiceFormFinish}
          >
            <Row gutter={16}>
              <Col span={43}>
                <Form.Item label="External #" name="invoiceNumber">
                  <Input disabled={!ability.can('update', subject('Invoice', invoice), 'invoiceNumber')} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Value" name="value">
                  <MoneyInput disabled={!ability.can('update', subject('Invoice', invoice), 'value')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Due" name="due">
                  <Input disabled={!ability.can('update', subject('Invoice', invoice), 'due')} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Estimated pay date" name="estimatedPayDate">
                  <DatePicker format={datePickerFormat} disabled={!ability.can('update', subject('Invoice', invoice), 'estimatedPayDate')} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Sent date" name="sentDate">
                  <DatePicker format={datePickerFormat} disabled={!ability.can('update', subject('Invoice', invoice), 'sentDate')} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Paid date" name="paidDate">
                  <DatePicker format={datePickerFormat} disabled={!ability.can('update', subject('Invoice', invoice), 'paidDate')} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Is active?" name="isActive" valuePropName="checked">
                  <Switch disabled={!ability.can('update', subject('Invoice', invoice), 'isActive')} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Allow group by phase?" name="groupByPhase" valuePropName="checked">
                  <Switch disabled={!ability.can('update', subject('Invoice', invoice), 'groupByPhase')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Internal note" name="description">
                  <TextArea rows={4} disabled={!ability.can('update', subject('Invoice', invoice), 'description')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Client note" name="clientNote">
                  <TextArea rows={4} disabled={!ability.can('update', subject('Invoice', invoice), 'clientNote')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Internal link" name="invoiceLink">
                  <Input
                    addonAfter={
                      <a href={invoiceForm.getFieldValue('invoiceLink')} target="_blank" rel="noreferrer">
                        <Button type="text" size="small">
                          Go
                        </Button>
                      </a>
                    }
                    disabled={!ability.can('update', subject('Invoice', invoice), 'invoiceLink')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Client link" name="clientInvoiceLink">
                  <Input
                    addonAfter={
                      <a href={invoiceForm.getFieldValue('clientInvoiceLink')} target="_blank" rel="noreferrer">
                        <Button type="text" size="small">
                          Go
                        </Button>
                      </a>
                    }
                    disabled={!ability.can('update', subject('Invoice', invoice), 'clientInvoiceLink')}
                  />
                </Form.Item>
              </Col>
            </Row>
            {ability.can('create', 'Invoice') && (
              <Form.Item shouldUpdate noStyle>
                {() =>
                  invoiceForm.getFieldValue('details').length === 0 ? (
                    <Button
                      disabled={invoice?.isActive}
                      type="dashed"
                      block
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Add line items from timesheet
                    </Button>
                  ) : null
                }
              </Form.Item>
            )}
            <Row gutter={16} justify="space-between">
              <Col>
                <Form.Item shouldUpdate noStyle>
                  {() =>
                    invoiceForm.getFieldValue('details').length !== 0 ? (
                      <Form.Item label="Line items date range" name="dateRange">
                        <DatePicker.RangePicker
                          allowClear={false}
                          inputReadOnly
                          open={false}
                          disabled={!ability.can('update', subject('Invoice', invoice), 'dateRange')}
                        />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
              <Col>
                {ability.can('create', 'Invoice') && (
                  <Form.Item shouldUpdate>
                    {() =>
                      invoiceForm.getFieldValue('details').length !== 0 ? (
                        <Button
                          disabled={!ability.can('update', subject('Invoice', invoice), 'dateRange')}
                          type="dashed"
                          icon={<EditOutlined />}
                          style={{ padding: '0 0.5rem' }}
                          onClick={() => setIsModalOpen(true)}
                        >
                          Edit line items
                        </Button>
                      ) : null
                    }
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className="invoice-table">
                <Form.Item name="details">
                  <InvoiceTableInternal />
                </Form.Item>

                <Form.Item name="details">
                  <InvoiceTableInternal isClientView groupByPhase={invoice.groupByPhase} />
                </Form.Item>

                <Form.Item name="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="markupPercentage" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="isActive" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Space>

      <Modal
        title="Timesheet data"
        open={isModalOpen}
        width="1200px"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        destroyOnClose
        footer={null}
      >
        <InvoiceCreator
          projectId={projectId}
          projectBillableRate={billableRate}
          onInvoiceCreated={data => onInvoiceEditCompleted(data)}
          editingInvoice={invoice.id ? invoice : null}
        />
      </Modal>
    </Modal>
  );
};

export default InvoiceModal;
