import useSWR from 'swr';
import { FetchResult } from '../entities';
import { Invoice } from '../entities/Invoice';
import { convertDateRange } from '../utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { isMoment, Moment } from 'moment';

type Result = FetchResult & {
  invoices: Invoice[] | undefined;
  saveInvoice: (payload: Invoice) => Promise<AxiosResponse<any>>;
  deleteInvoice: (invoiceId: string) => Promise<AxiosResponse<any>>;
};

export function useProjectInvoices(projectId: string): Result {
  const { data, error, mutate } = useSWR<Invoice[]>(`/projects/${projectId}/invoices`);

  const saveInvoice = useCallback(
    (invoice: Invoice) =>
      axios.put(`/projects/${projectId}/invoices`, {
        ...invoice,
        estimatedPayDate: momentToString(invoice.estimatedPayDate),
        sentDate: momentToString(invoice.sentDate),
        paidDate: momentToString(invoice.paidDate),
        dateRange: invoice.dateRange ? invoice.dateRange.map(momentToString) : [],
      }),
    [projectId],
  );
  const deleteInvoice = useCallback((invoiceId: string) => axios.delete(`/projects/${projectId}/invoices/${invoiceId}`), [projectId]);

  return {
    invoices: data && convertPaymentsDateRange(data),
    isLoading: !error && !data,
    isError: error,
    mutate,
    saveInvoice,
    deleteInvoice,
  };
}

const convertPaymentsDateRange = (invoices: Invoice[]): Invoice[] => {
  return invoices.map(invoice => {
    if (invoice.dateRange) {
      return {
        ...invoice,
        dateRange: convertDateRange(invoice.dateRange),
      };
    }
    return invoice;
  });
};

const momentToString = (date: any) => (isMoment(date) ? (date as Moment).format('YYYY-MM-DD') : null);
