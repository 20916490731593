import { useCallback, useEffect, useState } from 'react';
import { Button, Row, Col, Spin, message, Table, Space, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useProjectFinance } from '../../dal';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { Project, ProjectFinance } from '../../entities';
import { formatCurrency, formatDate, formatDateTime } from '../../common/utils';
import './ProjectFinance.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFInvoice from './PDFInovice';
import { useProjectInvoices } from '../../dal/useProjectInvoices';
import { getInvoiceRowCssClass, Invoice } from '../../entities/Invoice';
import InvoiceModal from './ProjectFinance.invoiceModal';
import MoneyInput from '../shared/MoneyInput';
import { FilePdfOutlined, FileAddOutlined } from '@ant-design/icons';
import { InvoiceStatusTag } from './InvoiceStatusTag';

const ProjectFinances = ({ project }: { project: Project }) => {
  const { id: projectId, client, autoCode: projectCode, name: projectName } = project;

  const { finance, mutate: mutateFinance, saveFinance } = useProjectFinance(projectId);
  const { invoices, mutate: mutateInvoice, saveInvoice, deleteInvoice } = useProjectInvoices(projectId);
  const { ability } = usePermissions();
  const [form] = useForm();
  const [openInvoice, setOpenInvoice] = useState<Invoice | null>(null);

  const onInvoiceDelete = useCallback(
    async (invoiceId: string) => {
      await deleteInvoice(invoiceId);
      await mutateInvoice();
      setOpenInvoice(null);
    },
    [deleteInvoice, mutateInvoice],
  );

  const onInvoiceFormFinish = useCallback(
    async (invoice: Invoice) => {
      await saveInvoice(invoice);
      await mutateInvoice();
      setOpenInvoice(null);
    },
    [mutateInvoice, saveInvoice],
  );

  const onFinish = useCallback(
    async (values: ProjectFinance) => {
      await saveFinance({ billableRate: values.billableRate });
      await mutateFinance();
      message.success('Data saved');
    },
    [saveFinance, mutateFinance],
  );

  useEffect(() => {
    form.resetFields();
  }, [form, projectId]);

  if (!invoices || !finance) return <Spin />;

  if (ability.can('view', 'Invoice')) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify="space-between">
          <Col>
            <Form layout="inline" initialValues={finance} onFinish={onFinish}>
              <Form.Item label="Billable rate" name="billableRate">
                <MoneyInput style={{ width: '75px' }} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Save</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col>
            <Button
              icon={<FileAddOutlined />}
              onClick={() =>
                setOpenInvoice({
                  ordinal: 0,
                  autoCode: '',
                  invoiceNumber: '',
                  value: 0,
                  paid: false,
                  description: '',
                  clientNote: '',
                  invoiceLink: '',
                  clientInvoiceLink: '',
                  due: '',
                  estimatedPayDate: null,
                  sentDate: null,
                  paidDate: null,
                  isActive: false,
                  dateRange: [null, null],
                  details: [],
                  markupPercentage: 0,
                  groupByPhase: false,
                })
              }
            >
              Create invoice
            </Button>
          </Col>
        </Row>

        <Table dataSource={invoices} rowClassName={getInvoiceRowCssClass} size="small" pagination={false} className="non-chat-table" rowKey="id">
          <Table.Column
            dataIndex="autoCode"
            title="Invoice #"
            render={(_, invoice: Invoice) => (
              <Button type="link" onClick={() => setOpenInvoice(invoice)}>
                {invoice.autoCode}
              </Button>
            )}
          />
          <Table.Column dataIndex="invoiceNumber" title="External #" />
          <Table.Column dataIndex="value" title="Value" render={(value: number) => formatCurrency(value)} />
          <Table.Column dataIndex="due" title="Due" />
          <Table.Column dataIndex="estimatedPayDate" title="Estimated pay date" render={(estimatedPayDate: string) => formatDate(estimatedPayDate)} />
          <Table.Column dataIndex="sentDate" title="Sent date" render={(sentDate: string) => formatDate(sentDate)} />
          <Table.Column dataIndex="paidDate" title="Paid date" render={(paidDate: string) => formatDate(paidDate)} />
          <Table.Column dataIndex="createdAt" title="Created at" render={(createdAt: string) => formatDateTime(createdAt)} />
          <Table.Column dataIndex="status" title="Status" render={(_, invoice: Invoice) => <InvoiceStatusTag invoice={invoice} />} />
          <Table.Column dataIndex="isActive" title="Is active?" render={(_, invoice: Invoice) => (invoice.isActive ? 'Active' : 'Draft')} />
          <Table.Column
            dataIndex="id"
            render={(_, invoice: Invoice) => (
              <PDFDownloadLink
                document={<PDFInvoice invoice={invoice} project={{ projectName, projectCode }} client={client} />}
                fileName={`Invoice-${invoice.autoCode}`}
              >
                <Button target="_blank" block icon={<FilePdfOutlined />} type="link">
                  Download
                </Button>
              </PDFDownloadLink>
            )}
          />
        </Table>

        {openInvoice && (
          <InvoiceModal
            projectId={projectId}
            billableRate={finance.billableRate}
            invoice={openInvoice}
            onCancel={() => setOpenInvoice(null)}
            onDelete={onInvoiceDelete}
            onSubmit={onInvoiceFormFinish}
          />
        )}
      </Space>
    );
  }

  return null;
};

export default ProjectFinances;
